import { useCallback, useMemo, useRef, useState } from 'react';

import {
	ConvoAIErrorMessage,
	ConvoAIResponseState,
	type ConvoAIStreamError,
	type ConvoAIStreamingState,
	useConvoAIStreaming,
} from '@atlassian/ai-agent-streaming';

import type { StreamingManagerProps, StreamingRequest, StreamingResponse } from '../../types';

export const shouldReportSLOFailureOnError = (error: ConvoAIStreamError) => {
	const sloFailureErrors: Array<ConvoAIErrorMessage> = [
		ConvoAIErrorMessage.TIMEOUT,
		ConvoAIErrorMessage.UNEXPECTED,
		ConvoAIErrorMessage.BAD_REQUEST,
	];
	return sloFailureErrors.includes(error.message.message_template as ConvoAIErrorMessage);
};

export const useStreaming = ({
	analyticsContext,
	issueConfig,
	streamingProps,
	requestHeaderOverrides,
	onSuggestionStreamUpdated,
	onStreamError,
	onStreamInit,
	onStreamAbortedByInitNew,
}: StreamingManagerProps) => {
	const [isStreaming, setIsStreaming] = useState(false);
	const [isInitiating, setIsInitiating] = useState(true);
	const prevResponseState = useRef<ConvoAIResponseState | null>(null);
	const streamCountRef = useRef(0);
	const getIsStreamingRef = useRef<() => boolean>(() => false);
	getIsStreamingRef.current = () => isStreaming;

	const streamingRequest = useMemo(
		() => ({
			ai_feature_input: {
				...streamingProps.input,
				suggested_issues_config: issueConfig,
			},
		}),
		[issueConfig, streamingProps.input],
	);

	const streamingConfig = useMemo(
		() => ({
			apiPath: `v2/ai-feature/jira/issue/source-type/${streamingProps.sourceType}/suggestions/stream`,
			enablePartialJson: true,
			enableFollowUp: false,
			headers: {
				'x-experience-id': streamingProps.experienceId,
				'x-product': requestHeaderOverrides?.overrideXProduct ?? analyticsContext.product,
			},
		}),
		[
			analyticsContext.product,
			streamingProps.experienceId,
			streamingProps.sourceType,
			requestHeaderOverrides?.overrideXProduct,
		],
	);

	const onStateChange = useCallback(
		(state: ConvoAIStreamingState<StreamingResponse>) => {
			if (prevResponseState.current !== state.responseState) {
				if (state.responseState === ConvoAIResponseState.Initialization) {
					if (isStreaming) {
						onStreamAbortedByInitNew();
					}
					onStreamInit(streamCountRef.current); // argument is "retryCount", so before self-increment

					setIsStreaming(true);
					if (isInitiating) {
						setIsInitiating(false);
					}

					streamCountRef.current++;
				}

				prevResponseState.current = state.responseState;
			}

			if (!isStreaming) {
				return;
			}

			switch (state.responseState) {
				case ConvoAIResponseState.AnswerPart: {
					const fullyFormedIssues = state.content?.suggested_issues?.slice?.(0, -1) ?? [];

					onSuggestionStreamUpdated(fullyFormedIssues);

					break;
				}
				case ConvoAIResponseState.FinalResponse: {
					setIsStreaming(false);

					// builds kept failing when using ConvoAIErrorMessage.SOME_ERROR
					// fixed by typecasting as ConvoAIErrorMessage
					if (!('suggested_issues' in state.content)) {
						// if there are are no suggested_issues, it's potentially due to bad json
						// log as an unexpected error as we are unsure if this pops up in ConvoAI
						onStreamError({
							type: 'ERROR',
							message: {
								message_template: 'UNEXPECTED' as ConvoAIErrorMessage,
								content: `Bad JSON: ${Object.keys(state.content).toString()}`,
								status_code: 500,
							},
						});
						break;
					}

					if (state.content?.suggested_issues.length === 0) {
						// no child issue information came back from the LLM
						// we clasify this as a not engouh information error
						onStreamError({
							type: 'ERROR',
							message: {
								message_template: 'NOT_ENOUGH_CONTENT_ERROR' as ConvoAIErrorMessage,
								content: 'Not enough content to generate work items from Confluence link.',
								status_code: 400,
							},
						});
						break;
					}

					onSuggestionStreamUpdated(state.content?.suggested_issues);
					break;
				}
				case ConvoAIResponseState.Error: {
					setIsStreaming(false);
					onStreamError(state.error);
					break;
				}
				default:
			}
		},
		[
			onSuggestionStreamUpdated,
			onStreamError,
			isStreaming,
			isInitiating,
			onStreamAbortedByInitNew,
			onStreamInit,
		],
	);

	const [_, refetch] = useConvoAIStreaming<StreamingRequest, StreamingResponse>(
		streamingRequest,
		streamingConfig,
		onStateChange,
	);

	const reset = useCallback(() => {
		setIsStreaming(false);
	}, []);

	return {
		isInitiating,
		isStreaming,
		getIsStreamingRef,
		refetch,
		reset,
	};
};
