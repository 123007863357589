import { useCallback, useEffect, useRef } from 'react';

import { v4 as uuidv4 } from 'uuid';

import { useStatefulObservableEffect } from '@atlassian/rx-hooks';

import {
	ConvoAIErrorMessage,
	type ConvoAIStreamingRequest,
} from '../api/ConvoAI/ConvoAIStreamingModels';
import {
	AnswerPartResponseMessage,
	ChannelIdResponseMessage,
	ErrorResponseMessage,
	FinalResponseMessage,
	FollowUpObject,
	FollowUpResponseMessage,
} from '../api/ConvoAI/ConvoAIStreamMessages';
import { convoAIStreamingEffect } from '../effects/convoAIStreamingEffect';
import { type UserConvoAIStreamingConfig } from '../models/ConvoAIStreamingConfig';
import {
	type ConvoAIStreamingState,
	getDefaultConvoAIStreamingState,
} from '../models/ConvoAIStreamingState';

export function useConvoAIStreaming<
	TConvoAIStreamingRequest extends ConvoAIStreamingRequest,
	TFinalResponse extends FinalResponseMessage = FinalResponseMessage<string>,
	TError extends ErrorResponseMessage = ErrorResponseMessage<`${ConvoAIErrorMessage}`>,
	TFollowUp extends FollowUpResponseMessage = FollowUpResponseMessage<FollowUpObject[]>,
	TAnswerPart extends AnswerPartResponseMessage = TFinalResponse,
	TChannelId extends ChannelIdResponseMessage = TFinalResponse,
>(
	request: TConvoAIStreamingRequest,
	config: UserConvoAIStreamingConfig,
	onStateChange?: (
		state: ConvoAIStreamingState<TFinalResponse, TError, TFollowUp, TAnswerPart, TChannelId>,
	) => void,
): [ConvoAIStreamingState<TFinalResponse, TError, TFollowUp, TAnswerPart, TChannelId>, () => void] {
	const refetchId = useRef<string>(uuidv4());

	const [state, setState] = useStatefulObservableEffect<
		ConvoAIStreamingState<TFinalResponse, TError, TFollowUp, TAnswerPart, TChannelId>,
		[TConvoAIStreamingRequest, UserConvoAIStreamingConfig, string]
	>(convoAIStreamingEffect, getDefaultConvoAIStreamingState(), [
		request,
		config,
		refetchId.current,
	]);

	useEffect(() => {
		onStateChange?.(state);
	}, [state, onStateChange]);

	const refetch = useCallback(() => {
		refetchId.current = uuidv4();
		setState({
			inputs: [request, config, refetchId.current],
			state: getDefaultConvoAIStreamingState(),
		});
	}, [request, config, setState]);

	return [state, refetch];
}
